<template>
    <div class="w-full m-8 bg-white rounded-lg p-4">

      <div
        class="flex justify-center text-2xl text-il-dark font-bold"
      >
        Clients Management
      </div>

      <div
        class="text-center text-gray-400 uppercase text-sm mt-2"
      >
        manage your clients
      </div>

      <div class="flex justify-center">
        <div
          v-if="!newClientEditing"
          @click="toggleNewClientEdit"
          class=" py-2 px-4 mt-2 uppercase rounded-lg text-sm cursor-pointer border border-il-dark text-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out"
        >
          <i class="fas fa-plus mr-2"></i> add a new client
        </div>
        <div v-else>
          <div class="flex w-300px text-center items-center mt-2">
            <div class="flex-grow">
              <input
                ref="newClientNameRef"
                type="text"
                class="w-full rounded-l-lg border border-gray-400 focus:border-il-dark outline-none p-1 font-bold text-center"
                v-model="newClientName"
                :readonly="createLoading"
                @keyup.enter="createClient"
                placeholder="New Client Name"
              >
            </div>

            <div
              v-if="!createLoading"
              class="flex flex-shrink"
            >
              <div
                class="flex-shrink bg-il-teal-faded hover:bg-il-teal text-white text-sm p-2 transition-all duration-200 ease-in-out cursor-pointer"
                @click="createClient"
              >
                <i class="fas fa-check-circle"></i>
              </div>
              <div
                class="flex-shrink bg-red-300 hover:bg-red-600 text-white text-sm p-2 rounded-r-lg transition-all duration-200 ease-in-out cursor-pointer"
                @click="toggleNewClientEdit"
              >
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
            <div
              v-else
              class="bg-il-teal text-white p-2 px-4 text-sm rounded-r-lg"
            >
              <i class="fas fa-circle-notch fa-spin"></i>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="clients.length > 0"
        class="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4"
      >
        <div
          class="m-2 shadow-md hover:shadow-xl rounded-lg p-4 transition-all duration-200 ease-in-out"
          v-for="client in clients"
          :key="client.id"
        >
          <div
            class="flex flex-col"
          >
            <div
              class="text-il-dark font-bold text-center text-xl"
            >
              {{ client.name }}
            </div>

            <div class="mt-2">
              <hr>
            </div>

            <div class="mt-4 text-il-dark font-bold">
              <span class="mr-2 text-gray-400"><i class="fas fa-stream"></i></span>
              Invoice Stats
            </div>

            <div class="grid grid-cols-2 text-sm text-gray-500 mt-1">
              <div>Saved</div>
              <div>{{ client.client_invoices ? client.client_invoices.length : 0 }}</div>

              <div>Sent</div>
              <div>{{ sentInvoicesCount(client.client_invoices) }}</div>

              <div>Seen</div>
              <div>{{ seenInvoicesCount(client.client_invoices) }}</div>
            </div>

            <div class="flex flex-col mt-2">
              <div
                @click="navigateToMX('clients/new-invoice', { client_uuid: client.uuid })"
                class="px-4 py-1 border border-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out rounded-lg text-il-dark uppercase text-sm font-bold cursor-pointer"
              >
                <i class="fas fa-file-invoice"></i>
                <span class="ml-3">Create new Invoice</span>
              </div>

              <div
                @click="navigateToMX('clients/invoices-history', { client_uuid: client.uuid })"
                class="mt-1 px-4 py-1 border border-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out rounded-lg text-il-dark uppercase text-sm font-bold cursor-pointer"
              >
                <i class="fas fa-history"></i>
                <span class="ml-2">Invoices History</span>
              </div>

              <div
                @click="navigateToMX('clients/settings', { client_uuid: client.uuid })"
                class="mt-1 px-4 py-1 border border-il-dark bg-white hover:bg-il-dark hover:text-white transition-all duration-200 ease-in-out rounded-lg text-il-dark uppercase text-sm font-bold cursor-pointer"
              >
                <i class="fas fa-cog"></i>
                <span class="ml-2">Client Settings</span>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
</template>

<script>
  import clientRequests from '../../helpers/clientRequests';
  import { mapGetters, mapMutations } from 'vuex';
  import { directive } from 'vue-tippy';
  import lodash from 'lodash';

  export default {
    mounted() {

    },
    data() {
      return {
        newClientEditing: false,
        newClientName: null,
        createLoading: false
      }
    },
    components: {

    },
    directives: {
      'v-tippy': directive,
    },
    methods: {
      ...mapMutations([
        'setUserCurrentCompanyX'
      ]),
      toggleNewClientEdit() {
        if(this.getIsOnTrialX || this.getIsSubscribedX) { // is on trial or subscribed
          if(this.clients.length < 10) { // allowed to create new client
            this.newClientEditing = !this.newClientEditing;
            if(this.newClientEditing) {
              this.$nextTick(function() {
                this.$refs.newClientNameRef.focus();
              });
            }
          } else { // Maximum clients amount already
            this.popErrorMX("A maximum of 10 Clients allowed per Company");
          }
        } else if(this.getUserX.emailVerifiedAt && this.clients.length < 3) {
          this.newClientEditing = !this.newClientEditing;
          if(this.newClientEditing) {
            this.$nextTick(function() {
              this.$refs.newClientNameRef.focus();
            });
          }
        } else {
          this.popErrorMX("Subscribe to Premium Plan for more clients");
        }
      },
      createClient() {
        if(!this.createLoading) {
          this.createLoading = true;
          clientRequests.createNewClient(this.newClientName)
          .then((response) => {
            this.setUserCurrentCompanyX(response.data.current_company);
            this.createLoading = false;
            this.newClientEditing = false;
            this.newClientName = null;
            this.$toast.success(response.data.message, {
              position: "bottom"
            });
          }).catch(error => {
            console.log(error);
            this.$toast.error(error.response.data.message, {
              position: "bottom"
            });
            this.createLoading = false;
          });
        }
      },
      sentInvoicesCount(invoices) {
        let result = 0;
        lodash.forEach(invoices, function(invoice) {
          if(invoice.sent_to_email) {
            result = result + 1;
          }
        });

        return result;
      },
      seenInvoicesCount(invoices) {
        let result = 0;
        lodash.forEach(invoices, function(invoice) {
          if(invoice.seen_at) {
            result = result + 1;
          }
        });

        return result;
      }
    },
    computed: {
      ...mapGetters([
        'getUserCurrentCompanyX',
        'getUserX',
        'getIsOnTrialX',
        'getIsSubscribedX'
      ]),
      clients() {
        if(this.getUserCurrentCompanyX) {
          return this.getUserCurrentCompanyX.clients;
        }
        return [];
      }
    },
  }
</script>